<template>
   <a-locale-provider :locale="zh_CN">
    <LoadPage v-if="isLoading"/>
    <div v-else id="app">
      <Header :class="showHeader?'':'noHeader'" :showMenu="showMenu" :showSearch="showSearch"></Header>
      <router-view class="appBody" :key="$route.path + $route.query.t"/>
      <Footer :class="showHeader?'':'noHeader'"></Footer>
      <RightNav></RightNav>
    </div>
  </a-locale-provider>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import RightNav from "@/components/RightNav";
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import LoadPage from "@/components/LoadPage.vue";
export default {
  components: {
    Header,
    Footer,
    RightNav,
    LoadPage
  },
  data() {
    return {
      isLoading:true,
      showMenu:false,
      showHeader:true,
      showSearch:true,
      zh_CN
    }
  },
  watch:{
    $route(to){
      this.showMenu=to.path==='/'?false:true
      this.showHeader=this.testHeader(to.path)
      this.showSearch=this.testSearch(to.path)
      window.scrollTo({
        top: 0
      })
    }
  },
  methods:{
    testHeader(path){
      switch(path){
        case "/login":
          return false
        case "/register":
          return false
        case "/statement":
          return false
        case "/forgetPassword":
          return false
        default:
          return true
      }
    },
    testSearch(path){
      switch(path){
        case "/cloudShop":
          return false
        case "/goodsDetail":
          return false
        default:
          return true
      }
    },
    loadService(){
      (function (w, d, e, x) {
        w[e] = function () {
        w.cbk = w.cbk || []
        w.cbk.push(arguments);
        }
        x = d.createElement("script");
        x.async = true;
        x.id = "zhichiScript";
        x.className="zhiCustomBtn";
        // ***为您的自定义域名
        x.src = "https://pchczyw.soboten.com/chat/frame/v6/entrance.js?sysnum=8d34770dce2742a8bb19e7bd421322f3";
        d.body.appendChild(x);
      })(window, document, "zc");
      // eslint-disable-next-line no-undef
      zc("config",{
        custom:true, //设置自定义生效 第二步
      })
    },
    testLoading(){
      setTimeout(() => {
        this.isLoading=false
      }, 1500)
    }
  },
  mounted() {
    this.testLoading()
    this.loadService()
  }
};
</script>
<style lang="less">
@import url(./main.less);
@media screen and (min-width: 1025px) {
  .appBody{
    min-width: 1248px;
    padding:0 24px;
  }
}
@media screen and (min-width: 1248px) {
  .appBody{
    min-width: 1200px;
    padding:0;
  }
}
</style>
