import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import("@/views/home.vue"),
    meta: {
      title: "慧聪行业资源网--工业品B2B垂直采购平台",
      content: {
        keywords: "慧聪资源网,供应求购,行业资源网,批发采购,行业资讯,买卖信息,供求信息,求购信息,信息服务,交易撮合,商机线索,热泵,空调,地暖,水处理,电子元器件,物联网,舞台灯光设备,专业音响灯光,工程机械,汽车配件,汽车用品,广电教育,家电,酒店用品,消防设备供求",
        description: "慧聪行业资源网是工业品B2B垂直采购平台，致力为用户提供“垂直、高效、精准”的商机线索，提高“找生意”和“做生意”的效率，以产业互联网的理念，打造集“信息服务、品牌服务、交易撮合服务、工具服务”为一体的B2B服务平台。目前hczyw.com已覆盖工业品热泵、空调、地暖、水处理、电子元器件、物联网、舞台灯光设备、专业音响灯光、工程机械、汽车配件、汽车用品、广电、涂料、塑料、家电、酒店用品、消防等31个主要工业品及原材料行业"
      }
    }
  },
  {
    path: "/freeSample",
    name: "freeSample",
    component: () =>
      import("@/views/freeSample.vue"),
    meta: {
      title: "免费拿样"
    }
  },
  {
    path: "/newsSearch",
    name: "newsSearch",
    component: () =>
      import("@/views/search/newsSearch.vue"),
    meta: {
      title: "资讯搜索"
    }
  },
  {
    path: "/recommendSearch",
    name: "recommendSearch",
    component: () =>
      import("@/views/search/recommendSearch.vue"),
    meta: {
      title: "推荐搜索"
    }
  },
  {
    path: "/goodsSearch",
    name: "goodsSearch",
    component: () =>
      import("@/views/search/goodsSearch.vue"),
    meta: {
      title: "商品搜索"
    }
  },
  {
    path: "/brandSearch",
    name: "brandSearch",
    component: () =>
      import("@/views/search/brandSearch.vue"),
    meta: {
      title: "供应商搜索"
    }
  },
  {
    path: "/purchaseSearch",
    name: "purchaseSearch",
    component: () =>
      import("@/views/search/purchaseSearch.vue"),
    meta: {
      title: "采购搜索"
    }
  },
  {
    path: "/service",
    name: "service",
    component: () =>
      import("@/views/serviceCenter/service.vue"),
    meta: {
      title: "客服中心"
    }
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import("@/views/serviceCenter/about.vue"),
    meta: {
      title: "关于我们"
    }
  },
  {
    path: "/supplyGoods",
    name: "supplyGoods",
    component: () =>
      import("@/views/supply/supplyGoods.vue"),
    meta: {
      title: "供应商品--慧聪行业资源网供应商产品",
      content: {
        keywords: "供应商品,供应商,工业品供应",
        description: "慧聪行业资源网主营批发、供应暖通与舒适家居、酒店用品、水处理、音响灯光、工程机械、物联网、汽车用品、消防、教育及LED等商品，厂家直销，可在线一键获取底价，快速联系优质供应商。"
      }
    }
  },
  {
    path: "/supplier",
    name: "supplier",
    component: () =>
      import("@/views/supply/supplier.vue"),
    meta: {
      title: "供应商"
    }
  },
  {
    path: "/publishPurchase",
    name: "publishPurchase",
    component: () =>
      import("@/views/publishPurchase.vue"),
    meta: {
      title: "发布采购"
    }
  },
  {
    path: "/purchaseList",
    name: "purchaseList",
    component: () =>
      import("@/views/purchaseList.vue"),
    meta: {
      title: "采购平台--垂直工业品一站式采购平台，精准商机+交易撮合，慧聪行业资源网让生意更简单",
      content: {
        keywords: "采购平台,求购信息,采购信息,工业品采购信息,B2B采购信息,B2B工业品采购平台",
        description: "慧聪行业资源网是工业品B2B垂直采购平台，致力为用户提供“垂直、高效、精准”的商机线索，提高“找生意”和“做生意”的效率，以产业互联网的理念，打造集“信息服务、品牌服务、交易撮合服务、工具服务”为一体的B2B服务平台。目前hczyw.com已覆盖工业品热泵、空调、地暖、水处理、电子元器件、物联网、舞台灯光设备、专业音响灯光、工程机械、汽车配件、汽车用品、广电、涂料、塑料、家电、酒店用品、消防等31个主要工业品及原材料行业。"
      }
    }
  },
  {
    path: "/newsList",
    name: "newsList",
    component: () =>
      import("@/views/news/newsList.vue"),
    meta: {
      title: "资讯列表"
    }
  },
  {
    path: "/recommendList",
    name: "recommendList",
    component: () =>
      import("@/views/recommend/recommendList.vue"),
    meta: {
      title: "资讯列表"
    }
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () =>
      import("@/views/news/newsDetail.vue"),
    meta: {
      title: "资讯详情"
    }
  },
  {
    path: "/shopAbout",
    name: "shopAbout",
    component: () =>
      import("@/views/cloudShop/about.vue"),
    meta: {
      title: "云商铺--专注B2B垂直行业，打造工业品B2B线上货柜",
      content: {
        keywords: "行业资源网,企业线索门户,云商铺,网上贸易,b2b,电子商务,批发采购,在线询盘交易,买卖信息,工业品垂直行业货源供应",
        description: "云商铺是专注为工业品B2B企业提供线上销售货场，同时全面展示企业实力，有助于提升企业品牌形象，为B2B工业品企业带来更多询盘机会，驱动业务的倍速增长。云商铺为数百万企业提供海量商机信息、建设自己的私域流量，沉淀自己的客户。"
      }
    }
  },
  {
    path: "/easyMerchants",
    name: "easyMerchants",
    component: () =>
      import("@/views/serviceCenter/easyMerchants.vue"),
    meta: {
      title: "易招通--一切为招商而来",
      content: {
        keywords: "易招通,交易撮合,品牌宣传,企业营销,拓客渠道",
        description: "易招通是慧聪网行业公司的信息+交易产品，聚焦国内工业品行业，企业通过易招通可以在慧聪行业资源网深度垂直的行业平台直接销售产品，并宣传客户的企业和品牌。主要用以解决网络贸易展示、数据、信用等问题，专为发展中企业量身定制，提供精准 商机撮合的落地服务。"
      }
    }
  },
  {
    path: "/superConditions",
    name: "superConditions",
    component: () =>
      import("@/views/serviceCenter/superConditions.vue"),
    meta: {
      title: "超级商情--汇集供采资讯的阵地",
      content: {
        keywords: "超级商情,慧聪行业超级商情,行业商情",
        description: "慧聪行业超级商情针对性、服务性、专业性、于一体，打通各个端口，以新媒体为载体，利用行业资讯和大数据分析，针对特定目标展开垂直行业的市场营销，帮助中小企业真正实现轻松做生意的目标。"
      }
    }
  },
  {
    path: "/tiktok",
    name: "tiktok",
    component: () =>
      import("@/views/serviceCenter/tiktok.vue"),
    meta: {
      title: "抖音竞价上热门--帮助B端企业实现多渠道全网营销",
      content: {
        keywords: "抖音竞价,头条搜索广告,抖音搜索广告,短视频营销,B端企业直播",
        description: "上热门是慧聪行业资源网为暖通与舒适家居、酒店用品、水处理、音响灯光、工程机械、物联网、汽车用品、消防、教育及LED等B端垂直工业品企业能够提高用户触达效果，推动线上业务变现，致力于构建B端工业品全网营销生态，助力B端企业营销增长，实现企业品宣效果。"
      }
    }
  },
  {
    path: "/cloudShop",
    name: "cloudShop",
    component: () =>
      import("@/views/cloudShop/cloudShop.vue"),
    meta: {
      title: "店铺详情"
    }
  },
  {
    path: "/goodsDetail",
    name: "goodsDetail",
    component: () =>
      import("@/views/cloudShop/goodsDetail.vue"),
    meta: {
      title: "商品详情"
    }
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import("@/views/register.vue"),
    meta: {
      title: "用户注册"
    }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import("@/views/login.vue"),
    meta: {
      title: "用户登录"
    }
  },
  {
    path: "/statement",
    name: "statement",
    component: () =>
      import("@/views/statement.vue"),
    meta: {
      title: "个人信息保护声明"
    }
  },
  {
    path: "/quote",
    name: "quote",
    component: () =>
      import("@/views/quote.vue"),
    meta: {
      title: "我要报价"
    }
  },
  {
    path: "/noticeList",
    name: "noticeList",
    component: () =>
      import("@/views/notice/noticeList.vue"),
    meta: {
      title: "公告列表"
    }
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () =>
      import("@/views/forgetPassword/forgetPassword.vue"),
    meta: {
      title: "忘记密码"
    }
  },
  {
    path: "/dynamicDetail",
    name: "dynamicDetail",
    component: () =>
      import("@/views/cloudShop/dynamicDetail.vue"),
    meta: {
      title: "动态详情"
    }
  },
  {
    path: "/removedShop",
    name: "removedShop",
    component: () =>
      import("@/views/cloudShop/removedShop.vue"),
    meta: {
      title: "店铺已下架"
    }
  }
];

const router = new VueRouter({
  routes,
  mode: 'hash'
});

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  const location = window.location;
  const hostname = location.hostname;
  const normalHostnames = ['localhost', 'www.hcmmt.com','hcmmt.com', '47.97.166.135', '192.168.1.25'];

  if (!normalHostnames.includes(hostname)) {
    // 如果是子域名
    const subdomain = hostname.split('.')[0];
    const baseHostname = hostname.split('.').slice(1).join('.');
    const port = location.port ? `:${location.port}` : '';

    if (to.name === 'cloudShop' || to.path === '/') {
      // 如果目标是 cloudShop 或根路径
      if (!to.params.link) {
        // 只有在没有 link 参数时才添加，避免循环
        next({
          name: 'cloudShop',
          params: { ...to.params, link: subdomain },
          replace: true
        });
      } else {
        next();
      }
    } else {
      // 如果目标不是 cloudShop，包括首页，更改 URL 为正常格式并导航
      const newUrl = `http://${baseHostname}${port}/#${to.fullPath}`;
      window.location.href = newUrl;
      return; // 防止继续执行 next()
    }
  } else if (to.name === 'cloudShop' && to.params.link) {
    // 如果在正常主机名上导航到 cloudShop 并带有 link 参数
    // 重定向到子域名 URL
    const port = location.port ? `:${location.port}` : '';
    const newUrl = `http://${to.params.link}.${hostname}${port}/`;
    window.location.href = newUrl;
    return; // 防止继续执行 next()
  } else {
    // 正常导航
    next();
  }
});


// 添加一个全局的后置钩子来处理子域名的 URL
router.afterEach((to) => {
  const hostname = window.location.hostname;
  const normalHostnames = ['localhost', 'www.hcmmt.com', 'hcmmt.com', '47.97.166.135', '192.168.1.25'];

  if (!normalHostnames.includes(hostname) && to.name === 'cloudShop') {
    // 如果是子域名且路由到了 cloudShop，修改 URL
    history.replaceState(null, '', '/');
  }
});

export default router;
